<template>
	<v-container
		class="pa-2"
		fluid>
		<div style="position: absolute; right: 10px; z-index: 20">
			<CasinoMenu fab></CasinoMenu>
		</div>
		<router-view></router-view>
	</v-container>
</template>

<script>
import CasinoMenu from "../components/shared/header/CasinoMenu.vue";

export default {
	components: { CasinoMenu },
	comments: false,
};
</script>
