var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-2", attrs: { fluid: "" } },
    [
      _c(
        "div",
        {
          staticStyle: { position: "absolute", right: "10px", "z-index": "20" },
        },
        [_c("CasinoMenu", { attrs: { fab: "" } })],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }